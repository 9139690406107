<template>
    <main>
        <section>
            <h1>Page not found (404)</h1>
            <h2>Uh Oh, <b>Ball searched.</b></h2>
            <br>
            <p>Operator called!<br>
                <a href="/">Please follow this way to unstuck ball.</a></p>
        </section>
    </main>
</template>

<script>
export default {
    name: "NotFound"
}
</script>

<style scoped>

</style>
import Welcome from '@/Welcome';
import Errored from '@/components/Errored';
import Shell from '@/components/Shell';
import SupporterThanks from '@/components/SupporterThanks';
import Supporter from '@/components/Supporter';
import List from '@/components/List';
import MachineView from '@/components/MachineView';
import Billing from '@/components/Billing';
import Edit from '@/components/Edit';
import ShadowList from '@/components/ShadowList';
import NotFound from '@/components/NotFound';
import SupporterActive from '@/components/SupporterActive';

let routes = {
    mode: 'history', // https://stackoverflow.com/questions/34623833/how-to-remove-hashbang-from-url
    routes: [
        {
            path: '/',
            component: Welcome
        },
        {
            path: '/operator-error/:status',
            component: Errored
        },
        {
            path: '/app',
            component: Shell,
            redirect: '/app/list',
            children: [
                {path: '/app/supporter/thank-you', component: SupporterThanks, meta: {user_only: true}},
                {path: '/app/supporter/active', component: SupporterActive, meta: {user_only: true, customer_only: true}},
                {path: '/app/supporter', component: Supporter, meta: {user_only: true}},

                {path: '/app/list', component: List, meta: {user_only: true}},
                {path: '/app/list/:page', component: List, meta: {user_only: true}},
                {path: '/app/machine/:oid', component: MachineView, meta: {user_only: true}},
                {path: '/app/billing', component: Billing, meta: {user_only: true}},

                {path: '/app/edit/:uid', component: Edit, meta: {admin_only: true}},
                {path: '/app/shadowlist', redirect: '/app/shadowlist/1', meta: {admin_only: true}},
                {path: '/app/shadowlist/:page', component: ShadowList, meta: {admin_only: true}},
            ]
        },
        {path: '*', component: NotFound} // 404 in 'history' mode
    ]
}

export default routes;
import axios from 'axios';
import Vue from 'vue';
import firebase from 'firebase/app';

export default {

    description: `

    <model v-model='model' api='/blah' :poll_seconds='120'></model>
    `,

    props: {
        value: {
            required: false,
            description: `
            The model from the server that is bound when using v-model. Should be null by default.
            `
        },
        api: {
            type: String,
            required: false,
            description: `
            URL for the api or null
            `
        },
        data: {
            required: false,
            description: `
            Data provided by the page if static
            `
        },
        poll_seconds: {
            type: Number,
            default: 90,
            minimum: 12,
            description: `
            Seconds between polling the server for more data.
            `
        },
        poll: {
            type: Boolean,
            default: true,
            description: `
            Turn polling off or on
            `
        },
        shouldError: {
            type: Function,
            required: false,
        },
        emit_null_on_load: {
            required: false,
            default: true,
            description: `
            Usually when the api url changes we emit a null object before we query the api, setting this flag to false stops the emit of null.
            `
        },
    },

    data() {
        return {
            local_poll_seconds: 2,
            chain: [],
            cancel_token: null,
            view_hidden: false,
        };
    },

    beforeMount() {
        this.setup_poll();
    },

    mounted() {
        if (this.$slots.default) {
            for (let slot of this.$slots.default) {
                if (slot.tag === undefined)
                    continue;
                this.chain.push(slot);
            }
            if (this.chain.length > 0) {
                for (let i = 1; i < this.chain.length; i++) {
                    let previous = this.chain[i - 1];
                    let slot = this.chain[i];
                    console.debug('  ', previous.tag, i - 1, '>', slot.tag, i);
                    previous.componentInstance.$on('output', (data) => {
                        slot.componentInstance.$data.data = data;
                    });
                }
                let slot = this.chain[this.chain.length - 1];
                slot.componentInstance.$on('output', (data) => {
                    this.$emit('input', data);
                });
            }
        }

        // document.addEventListener('visibilitychange', this.view_visible_changed, false);

        this.do_poll();
    },

    beforeDestroy() {
        clearInterval(this.$options.interval_id);
        // document.removeEventListener('visibilitychange', this.view_visible_changed, false);
    },

    methods: {
        setup_poll() {
            this.stop_poll();

            if (this.poll) {
                let t = parseInt(this.poll_seconds);
                if (!Number.isInteger(t))
                    t = 50;
                this.local_poll_seconds = (t < 2) ? 2 : t;
                this.$options.interval_id = setInterval(this.do_poll, this.local_poll_seconds * 1000);
            }
        },

        stop_poll() {
            if (this.$options.interval_id) {
                clearInterval(this.$options.interval_id);
            }
            this.$options.interval_id = 0;
        },

        do_poll() {
            if (!this.api) {
                this.notify(this.data || []);
                return;
            }

            if (this.view_hidden) {
                return;
            }

            this.$emit('loading', true);
            firebase.auth().onAuthStateChanged((user) => {
                user.getIdToken().then(token => {
                    this.cancel_token = axios.CancelToken.source();
                    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
                    axios.get(Vue.prototype.base + this.api, {
                        cancelToken: this.cancel_token.token,
                        // headers: {
                        //     "accept": "application/json, text/plain, */*",
                        // }
                    }).then(this.success).catch(e => {
                        console.warn(e);
                        this.browser_notify(`${e.response.status} ${e.response.statusText} - Please try again later`);
                        window.location = "/operator-error/" + e.response.status;
                    });
                });
            });
        },

        success(res) {
            let data = null;
            this.$emit('loading', false);
            if (!res || !res.data || !res.data.data) {
                data = [];
            } else {
                data = res.data.data;
                console.log(new Date().toLocaleTimeString(), this.api, '(', data.length || Object.keys(data).length, ')')
            }
            this.notify(data);
            this.$emit('page', res.data.page);
            this.$emit('total_pages', res.data.total_pages);

            if (res.status === 206) // server results were truncated (too many)
                this.$emit('partial', true);
        },

        failed(err) {
            this.$emit('loading', false);
            if (axios.isCancel(err)) {
                console.debug('request cancelled', err);
                return;
            }
            if (!this.shouldError || this.shouldError(err)) {
                console.warn(err);
                console.error(`Error calling: ${Vue.prototype.base}${this.api}`);
            }
        },

        notify(data) {
            if (this.chain.length === 0) {
                this.$emit('input', data);
            } else {
                this.chain[0].componentInstance.$data.data = data;
            }
        },

        view_visible_changed() {
            if (typeof document.hidden !== 'boolean')
                return;

            const was_hidden_before = this.view_hidden;
            this.view_hidden = document.hidden;

            if (!this.api || !this.poll)
                return;

            if (!this.view_hidden && was_hidden_before) {
                this.do_poll();
            }
        },

        cancel() {
            if (this.cancel_token) {
                this.cancel_token.cancel();
            }
        },
    },

    watch: {
        poll() {
            this.setup_poll();
        },
        poll_seconds() {
            this.setup_poll();
        },
        data() {
            this.notify(this.data);
        },
        api() {
            if (this.emit_null_on_load) {
                this.notify(null);
            }
            if (this.cancel_token) {
                this.cancel_token.cancel();
            }
            this.do_poll();
        }
    },

    render(createElement) {
        // render children
        return createElement('span', {class: 'model hidden'}, this.$slots.default);
    },
};

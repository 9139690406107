<template>
    <div>
        <div class="supporter">
            <div class="box">
                <h3>Become a supporter</h3>
                <h1>$8.99<sub><small>/ month</small></sub></h1>
                <br>
                <p><small>Payment handled by Stripe. Cancel any time.</small></p>
                <button id="basic-plan-btn" class="buybtn">Become a Supporter</button>
            </div>
            <div class="box">
                <h3>Pinsales</h3>
                <p>Pinsales is a passion project and the money raised from pinsales goes into paying the bills and making the product better.</p>
                <p>Become a pinsale supporter and get access to the following:</p>
                <ul>
                    <li>See all pinball adverts history on record</li>
                    <li>Access to individual machine statistics</li>
                    <li>Access to historical prices</li>
                    <!--                    <li>Notifications</li>-->
                </ul>
                <br>
                <div id="error-message"></div>
            </div>
        </div>
    </div>
</template>

<script>

import Vue from 'vue';
import firebase from 'firebase';
import axios from 'axios';

export default {
    name: "Supporter",
    methods: {

        // TODO this page needs mobile support

        init_stripe() {

            firebase.auth().onAuthStateChanged((user) => {
                user.getIdToken().then(token => {
                    this.cancel_token = axios.CancelToken.source();
                    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
                    axios.get(Vue.prototype.base + "/billing/setup", {
                        cancelToken: this.cancel_token.token,
                    }).then(res => {
                        if (res.status !== 200) {
                            this.browser_notify(`${res.status} ${res.statusText} - Please try again later`);
                            throw res;
                        }
                        const publishableKey = res.data.publishableKey;
                        const supporterPriceId = res.data.supporterPrice;
                        const stripe = window.Stripe(publishableKey);
                        // Setup event handler to create a Checkout Session when button is clicked
                        document.getElementById("basic-plan-btn").addEventListener("click", function () {
                            axios.post(Vue.prototype.base + "/billing/create-checkout-session", {
                                priceId: supporterPriceId
                            }).then(res => {
                                stripe.redirectToCheckout({sessionId: res.data.sessionId});
                            }).catch(err => {
                                this.browser_notify("Issue talking to Stripe", err.statusText, '/');
                                throw err;
                            });
                        });
                    }).catch(e => {
                        console.warn(e);
                        this.browser_notify(`${e.response.status} ${e.response.statusText} - Please try again later`);
                        window.location = "/operator-error/" + e.response.status;
                    });
                });
            });
        },
    },
    mounted() {
        // load the stripe sdk via js to not load on every page
        // let stripe_script = document.createElement('script');
        // stripe_script.onload = this.init_stripe;
        // stripe_script.src = "https://js.stripe.com/v3/";
        // document.head.appendChild(stripe_script);
        this.init_stripe();
    },
}
</script>

<style lang="stylus" scoped>

.supporter {
    display flex;
    justify-content flex-start
}

.box h3 {
    color: white;
    text-shadow 1px 1px 1px rgba(0, 0, 0, .5)
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.box {
    font-size large
    font-weight lighter
    line-height 1.5;
    text-shadow 1px 1px 0px rgba(0, 0, 0, .4)
    padding: 1rem;
    border-radius: 4px;
    background: #0e58e9;
    margin: 5rem auto;
    width: 40% !important;
    max-width: 35rem;
    color: white;
}


.box:nth-of-type(1) {
    h3 {
        color: black !important;
        text-shadow none;
        border-bottom 1px solid rgba(0, 0, 0, .3);
    }
    background: lightgrey;
    color: black !important;
    text-shadow none;
}

.buybtn {
    padding: 1rem 3rem;
    border: 2px solid transparent;
    color: white !important;
    background: rgba(0, 0, 0, .25);
    border-radius: 4px;
    text-transform: uppercase;
    font-size: .85rem;
    box-shadow none;
    margin: 1rem auto;
}

.buybtn:hover {
    color: white !important;
    background: #1022DE;
    text-decoration: none;
    border: 2px solid rgba(0, 0, 0, .2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

small {
    font-size: .9rem;
}

</style>
<template>
    <div>
        <aside>
            <section>
                <h2>Thank you</h2>
            </section>
        </aside>
        <main>
            <section>
                <h3>Thank you for support!</h3>
                <p>The money raised will go straight to maintening this application and putting food on the table.</p>
                <p>Your account has been updated. <b><a @click="signout">Please sign-in again</a></b> for the application to reflect that.</p>
                <pre></pre>
            </section>
        </main>
    </div>
</template>

<script>
import Vue from 'vue';
import firebase from 'firebase';
import axios from 'axios';

export default {
    name: "SupporterThanks",
    data() {
        return {
            session_id: null,
        };
    },
    mounted() {
        // load the stripe sdk via js to not load on every page
        // let stripe_script = document.createElement('script');
        // stripe_script.onload = this.init_stripe;
        // stripe_script.src = "https://js.stripe.com/v3/";
        // document.head.appendChild(stripe_script);
        this.init_stripe();
    },
    methods: {
        signout() {
            firebase.auth().signOut().then(function () {
                console.log('firebase signed out');
                window.location.href = '/';
            }, function (error) {
                console.log('firebase COULD NOT SIGN OUT', error);
            });
        },
        init_stripe() {
            const urlParams = new URLSearchParams(window.location.search);
            const sessionId = urlParams.get("session_id");

            // QUESTION how do I validate that the user is still a customer.
            //      ANSWER I don't think I need to use webhooks, I think I need to check upon login - good enough
            //      Use webhooks for personal notifications

            if (!sessionId) {
                console.warn("no session id?");
                return;
            }
            this.session_id = sessionId;

            firebase.auth().onAuthStateChanged(async (user) => {
                user.getIdToken().then(async token => {
                    this.cancel_token = axios.CancelToken.source();
                    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
                    this.pull_data(user.uid);
                });
            });
        },

        pull_data(user_uid) {
            axios.get(Vue.prototype.base + "/billing/checkout-session?sessionId=" + this.session_id, {
                cancelToken: this.cancel_token.token,
            }).then(async res => {
                if (res.status !== 200) {
                    this.browser_notify(`${res.status} ${res.statusText} - Please try again later`);
                    throw res;
                }
                if (res.data.payment_status !== "paid") {
                    console.warn("user has not paid");
                    window.location = "/operator-error/PAYMENT-FAILED"
                    return;
                }
                const sub_id = res.data.subscription;

                this.set_user_subscription(user_uid, sub_id);

            }).catch(e => {
                console.warn(e);
                this.browser_notify(`${e.response.status} ${e.response.statusText} - Please try again later`);
                window.location = "/operator-error/" + e.response.status;
            });
        },

        set_user_subscription(user_uid, sub_id) {
            axios.post(Vue.prototype.base + "/user/set-user-subscription", {
                data: {
                    user_id: user_uid,
                    sub_id: sub_id
                }
            }).then(res => {
                if (res.status === 200)
                    this.browser_notify("Success! Thank you.");
            }).catch(err => {
                console.warn(err);
            });
        },
    }
}
</script>

<style scoped>

</style>
<template>
    <div>
        <aside>
            <section>
                <h2>Edit <span v-if="adinfo">&gt; {{adinfo.title}}</span></h2>
                <!--            <router-link to="/test">Go to Test</router-link>-->
            </section>
        </aside>
        <main>
            <section>
                <div class="panel">
                    <table v-if="adinfo">
                        <tr>
                            <th>Field</th>
                            <th>Value</th>
                            <th>&nbsp;</th>
                        </tr>
                        <tr>
                            <td class="thin">Title</td>
                            <td><a target="_blank" rel="noopener noreferrer" :href="adinfo.url">{{adinfo.title}}</a></td>
                            <td class="thin textlight">{{adinfo.id}}</td>
                        </tr>
                        <tr>
                            <td class="thin">Machine(?)</td>
                            <td>{{adinfo.name}} <sup>{{adinfo.short}}</sup></td>
                            <td>
                                <!--                            <small class="textlight fright">{{adinfo.opdb_id}}</small>-->
                                <label>Update Machine Match:</label>
                                <MachineSearchField @selected="on_machine_changed"/>
                            </td>
                        </tr>
                        <tr>
                            <td class="thin">Shadow Banned</td>
                            <td>{{adinfo.shadowbanned}}</td>
                            <td><label>Shadow ban this title (hide it):</label><br>
                                <a @click="ban" v-if="!adinfo.shadowbanned">Shadow Ban</a>
                                <a @click="unban" v-if="adinfo.shadowbanned">Unshadow Ban</a></td>
                        </tr>
                        <tr>
                            <td class="thin">Marked as <i>Project</i></td>
                            <td><sup class="project" v-if="adinfo.is_project" title="This is a project game, likely not working or missing parts">Project</sup><sup class="not project" v-else>Working Condition</sup></td>
                            <td><label>This game is a project / not working:</label><br>
                                <a @click="mark_as_project" v-if="!adinfo.is_project">Mark as Project</a>
                                <a @click="unmark_as_project" v-if="adinfo.is_project">Mark as Working Condition</a></td>
                        </tr>
                        <tr>
                            <td class="thin">Marked as <i>Scam</i></td>
                            <td><sup class="scam" v-if="adinfo.is_scam" title="This is very likely to be a scam">SCAM</sup><sup class="not scam" v-else>Legit</sup></td>
                            <td><label>This game is likely to be a scam</label><br>
                                <a @click="mark_as_scam" v-if="!adinfo.is_scam">Mark as Scam</a>
                                <a @click="unmark_as_scam" v-if="adinfo.is_scam">Mark as Legitimate Sale</a></td>
                        </tr>
                        <tr>
                            <td class="thin">Delete</td>
                            <td>&nbsp;</td>
                            <td><label>Delete from db (get rid of old duplicates):</label><br>
                                <a @click="del">Delete</a></td>
                        </tr>
                    </table>
                </div>
            </section>
        </main>
        <model ref="model" v-model="adinfo" :api="`/forsale/by_ad/${this.uid}`"></model>
    </div>
</template>

<script>
import Vue from 'vue';
import MachineSearchField from '@/components/MachineSearchField';

export default {
    name: "AdEdit",
    components: {
        MachineSearchField
    },
    data: () => {
        return {
            adinfo: null,
        }
    },
    watch: {
        adinfo(val) {
        }
    },
    computed: {
        uid() {
            return this.$route.params.uid;
        }
    },
    methods: {
        async on_machine_changed(machine) {
            let res = await this.axios.post(Vue.prototype.base + `/forsale/${this.uid}/change_to/${machine.id}`)
            this.$refs.model.do_poll();
            this.browser_notify('Machine Updated', 'New Matching Machine');
        },
        async ban() {
            let res = await this.axios.post(Vue.prototype.base + `/forsale/${this.uid}/shadow_ban/true`)
            this.$refs.model.do_poll();
            this.browser_notify('Ad Updated', 'Shadow banned');
        },
        async unban() {
            let res = await this.axios.post(Vue.prototype.base + `/forsale/${this.uid}/shadow_ban/false`)
            this.$refs.model.do_poll();
            this.browser_notify('Ad Updated', 'Un-shadow banned');
        },
        async mark_as_project() {
            let res = await this.axios.post(Vue.prototype.base + `/forsale/${this.uid}/mark_as_project/true`)
            this.$refs.model.do_poll();
            this.browser_notify('Ad Updated', 'Marked as project');
        },
        async unmark_as_project() {
            let res = await this.axios.post(Vue.prototype.base + `/forsale/${this.uid}/mark_as_project/false`)
            this.$refs.model.do_poll();
            this.browser_notify('Ad Updated', 'Un-marked as project');
        },
        async mark_as_scam() {
            let res = await this.axios.post(Vue.prototype.base + `/forsale/${this.uid}/mark_as_scam/true`)
            this.$refs.model.do_poll();
            this.browser_notify('Ad Updated', 'Marked as scam!');
        },
        async unmark_as_scam() {
            let res = await this.axios.post(Vue.prototype.base + `/forsale/${this.uid}/mark_as_scam/false`)
            this.$refs.model.do_poll();
            this.browser_notify('Ad Updated', 'Un-marked as scam (legit)');
        },
        async del() {
            if (!confirm(`Warning, the following will be removed from DB: \n\n${this.adinfo.title}`))
                return;
            let res = await this.axios.post(Vue.prototype.base + `/forsale/delete/${this.uid}`)
            this.$refs.model.do_poll();
            this.browser_notify('Ad Deleted', 'Removed', '/app/list');
        },
    },
}
</script>

<style scoped>

</style>
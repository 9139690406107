<template>
    <main></main>
</template>

<script>
export default {
    name: "Billing"
}
</script>

<style scoped>

</style>
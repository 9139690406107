<template>
    <div>
        <header>
            <section>
                <div class="logo">
                    <img src="/img/pinsales_logo.png" alt="Pinsales" id="logo">
                </div>
            </section>
        </header>
        <main>
            <section>
                <h2>Pinsale BETA</h2>
                <br>

                <div class="center info">
                    <h3>Pinsales tracks the Pinball Machines for sale in Australia and the evolution of prices.</h3>
                </div>

                <br>
                <div>
                    <div id="firebaseui-auth-container" class=""></div>
                </div>
            </section>
        </main>
        <footer>
            <section>
                <sup>PLEASE NOTE: this website takes no responsibility for incorrect information, although best efforts are
                    made to be as accurate as possible: the information is automatically gathered and provided "as is", USE AT YOUR OWN RISK, and
                    remember that scams are out there and can skew the information. Always check that the vendor is genuine
                    via video interview and/or custom picture requests. If it is too good to be true, it probably is.</sup>
            </section>
        </footer>
        <br>
    </div>
</template>

<script>
import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';

export default {
    name: "Welcome",
    data: () => {
        return {};
    },
    mounted() {

        if (document.querySelector('#firebaseui-auth-container')) {
            const ui = new firebaseui.auth.AuthUI(firebase.auth());
            ui.start('#firebaseui-auth-container', {
                signInSuccessUrl: '/app/list/',
                signInFlow: 'popup',
                signInOptions: [
                    {
                        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                        clientId: '505749904418-3jjh3cb0veglc6rpjhga000ulmat7fmk.apps.googleusercontent.com'
                    },
                ],
            });
        }
    },
}
</script>

<style scoped>

</style>
<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import Vue from 'vue';

export default {
    name: 'App',
    created() {
        Vue.prototype.firebase = window.firebase;
    },
    components: {}
}
</script>

<style lang="stylus">
html, body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background: #f3f5f9;
    color: #111;
    font-weight: 400;
    font: .97rem / 2.0 'Roboto', sans-serif;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: subpixel-antialiased;
    text-rendering: geometricPrecision;
}

body {
    margin: 0;
}

*, *:before, *:after {
    box-sizing: inherit;
}

header {
    background: #ffcd11;
    padding: 10px 0 3px 0;
}

header section {
    max-width: 1366px;
    margin: 0 auto;
    padding: 0 1rem;
}

.inner {
    background: white;
}

main section {
    display: block;
    max-width: 1366px;
    margin: 2rem auto;
    padding: 1rem 1rem;
}


.shell > div > aside {
    /*background: #ffcd11;*/
    background: #f5f5f5;
    box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, .14), inset 0 3px 1px -2px rgba(0, 0, 0, .2), inset 1px 0 6px 0 rgba(0, 0, 0, .22);
    padding: .1rem 0;
}

aside {
    background: #fff;
}

aside section {
    max-width: 1366px;
    margin: 2rem auto;
    padding: 0 1rem;
}

footer {
    padding: 2rem 0;
    background: #f3f5f9;
}

footer section {
    max-width: 1366px;
    margin: 0 auto;
    padding: 0 1rem;
    line-height: 1.5;
}

h1 {
    display: inline-block;
    border-radius: 7px;
    font-weight: 300;
}

h2 {
    font-weight: 300;
    color: #888;
    line-height: 1;
    padding: 0;
    margin: 1rem 0;
}

h3 {
    font-weight: lighter;
    color: #333;
    line-height: 1;
    font-size: 1.5rem;
    margin: 1rem 0;
}

table {
    width: 100%;
    font: inherit;
    -webkit-text-size-adjust: none;
    border-collapse: collapse;
}

td {
    padding: .5rem 1rem .5rem 0;
    font: inherit;
    -webkit-text-size-adjust: none;
    font-size: .9rem;
}

td:last-child {
    padding-right: 0;
}

.logo {
    display: inline-block;
}

#logo {
    height: 4rem;
    cursor: pointer;
}

tr, tbody tr {
    border-bottom: 1px solid #ccc;
}

tbody:last-child tr {
    border: none;
}

th {
    padding: 1rem 1rem 1rem 0;
    text-align: left;
    color: #555;
    font-size: inherit;
    font-weight: 300;
}

.mid {
    max-width: 400px;
}

.sm {
}

.nowrap {
    white-space: nowrap;
}

th:last-child {
    padding-right: 0;
}


sup {
    color: gray;
    line-height: 1.5;
}

sup.project, sup.scam {
    background-color: #1022DE;
    border-radius: 4px;
    color: white;
    font-size: xx-small;
    font-weight: bold;
    margin: 0.2rem .3rem .2rem 0;
    padding: 0.2rem 0.3rem;
    position: relative;
    top: 2px;
    cursor: default;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .6);
    text-transform: uppercase;
    //box-shadow: 0 0 3px rgba(0, 0, 0, .4);
    transform: rotate(-7deg);
    opacity: .75;
    display: inline-block;
}

.old sup.project {
    background-color: #444;
}

sup.scam {
    background-color: #dd0000;
    opacity: .9;
}

sup.project.not, sup.scam.not {
    background-color: mediumseagreen;
    transform: rotate(0deg);
}

.search {
    float: right;
    margin-top: -.5rem;
}

.signin {
    float: right;
    margin: .1rem 0 0 0;
}

.clear {
    display: block;
    clear: both;
}

.loader {
    width: 50px;
    margin: 8rem auto 6rem auto;

    img {
        width: 24px;
    }
}

#search_icon {
    vertical-align: middle;
    display: inline-block;
    margin: 0 .3rem .2rem .5rem;
    width: 21px;
    height: 21px;
    opacity: .5;
}

label {
    color: #999;
    font-weight: lighter;
}

input[type=text] {
    width: 20rem;
    padding: 10px 10px;
    font-size: inherit;
    background: white !important;
    border: 0;
    border-radius: 2px;
    line-height: 1.35;
    /*border-radius: 7px;*/
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

input[type=text]::placeholder {
    font-size: .9em;
}

.cap {
    text-transform: capitalize;
}

.allcaps {
    text-transform: uppercase;
}

.bold {
    font-weight: bold;
}

.thin {
    font-weight: lighter;
}

.tright {
    text-align: right;
}

.center {
    text-align: center;
}

.textlight {
    color: #999;
}

.crossed {
    text-decoration line-through;
}

.info {
    padding: 1rem 0;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
}

.old {
    color: gray !important;
    opacity: .65;

    a {
        color: gray !important;
    }
}

a, a:visited, a:active {
    color: #3342e3;
    text-decoration: none;
    cursor: pointer;
}

a:hover {
    text-decoration: underline;
}

.fright {
    float: right;
}

.fleft {
    float: left;
}

nav {
    li {
        list-style: none;
        display: inline-block;
        margin-left: 1rem;

        a {
            border: 2px solid transparent;
            color: #222 !important;
            background: rgba(0, 0, 0, .06);
            padding: .4rem .8rem;
            border-radius: 4px;
            text-transform: uppercase;
            font-size: .85rem;
            box-shadow none;
        }

        a:hover {
            color: white !important;
            background: #1022DE;
            text-decoration: none;
            border: 2px solid rgba(0, 0, 0, .2);
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
        }
    }
}

a.router-link-active {
    font-weight: normal;
    border: 2px solid rgba(0, 0, 0, .3);
    box-shadow: inset 0 .1rem .3rem rgba(0, 0, 0, .3);
}

td {
    a.router-link-exact-active {
        border: none;
        box-shadow: none;
    }
}

.banner {
    a.router-link-active {
        border: none;
        box-shadow: none;
    }
}

.pastille {
    display: inline-block;
    border: 1px solid transparent;
    color: #222 !important;
    background: rgba(0, 0, 0, .05);
    padding: .1rem .8rem;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: .85rem;
    margin-right: 1rem;
}

.pastille.active {
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, .3);
    color: #222 !important;
    background: transparent;
    padding: .1rem .8rem;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: .85rem;
    margin-right: 1rem;
    box-shadow: inset 0 .1rem .3rem rgba(0, 0, 0, .3);
}

.pastille:hover {
    color: white !important;
    background: #1022DE;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, .2);
}

.panel {
    background: #f3f4f6;
    padding: 1rem;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14), 0 3px 1px -4px rgba(0, 0, 0, .2), 0 1px 15px 0 rgba(0, 0, 0, .12);
    border-radius: 4px;
    border: 1px solid #fff;
}

.hidden {
    display: none !important;
}

.banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #0e58e9;
    padding: .3rem 1rem;
    color: white;
    text-align: center;
    font-size: .8rem;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, .5);

    a {
        text-decoration: underline;
        color: white;
    }
}

</style>

<style lang="css">

@media only screen
and (max-device-width: 812px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
    nav ul {
        margin: 1rem 0;
        padding: 0 !important;
    }

    nav ul li, nav ul li a {
        display: block;
        margin: 0 !important;
    }

    main section {
        margin: 0 auto;
    }

    aside section {
        margin: 1rem 0;
    }

    .fright {
        float: none !important;
    }

    .search {
        float: none !important;
    }

    .search input[type=text] {
        margin: 1rem 0;
    }

    #search_icon {
        display: none;
    }

    .tright {
        text-align: left;
    }

    table th {
        display: none;
    }

    table td {
        padding: inherit !important;
        display: block;
        text-align: left;
    }

    table td:first-child {
        margin-top: 1rem;
    }

    table td:last-child {
        margin-bottom: 1rem;
    }

    table.list td:nth-child(2):before {
        content: 'Price';
        display: inline-block;
        width: 30%;
        color: gray;
    }

    table.list td:nth-child(3):before {
        content: 'Machine Match';
        display: inline-block;
        width: 30%;
        color: gray;
    }

    table.list td:nth-child(4):before {
        content: 'Manufacturer';
        display: inline-block;
        width: 30%;
        color: gray;
    }

    table.list td:nth-child(5):before {
        content: 'Location';
        display: inline-block;
        width: 30%;
        color: gray;
    }

    table.list td:nth-child(6):before {
        content: 'Source';
        display: inline-block;
        width: 30%;
        color: gray;
    }

    table.list td:nth-child(7):before {
        content: 'First Seen';
        display: inline-block;
        width: 30%;
        color: gray;
    }

    table.list td:nth-child(8):before {
        content: 'Last Seen';
        display: inline-block;
        width: 30%;
        color: gray;
    }

    table.list td:nth-child(9):before {
        content: 'Duration:';
        display: inline-block;
        width: 30%;
        color: gray;
    }

    table.machine_details td h2 {
        margin: 1rem 0 !important;
    }

    table.machine_details td {
        margin: 0 !important;
    }

    table.machine_details td.image img {
        width: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    ul.price_range {
        margin-top: -2rem !important;
    }
}
</style>
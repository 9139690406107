<template>
    <div class="shell">
        <header>
            <section>
                <div class="logo">
                    <router-link tag="div" to="/app/list"><img src="/img/pinsales_logo.png" alt="Pinsales" id="logo"></router-link>
                </div>
                <div class="fright">
                    <nav>
                        <ul>
                            <li>
                                <router-link to="/app/list">For Sale</router-link>
                            </li>
                            <li>
                                <router-link to="/app/supporter/active" v-if="subscription && subscription.status == 'active'">Supporter</router-link>
                                <router-link to="/app/supporter" v-else>Become a Supporter</router-link>
                            </li>
                            <li v-if="rbac && rbac.admin">
                                <router-link to="/app/shadowlist">Shadow Bans</router-link>
                            </li>
                            <!--                            <li v-if="user">-->
                            <!--                                <router-link to="/app/billing">Billing</router-link>-->
                            <!--                            </li>-->
                            <li v-if="user">
                                <a @click="logout">Sign Out, {{ user.displayName.split(' ')[0] }}</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </section>
        </header>

        <router-view class="inner"></router-view>

        <div class="clear">&nbsp;</div>

        <footer>
            <section>
                <sup>PLEASE NOTE: this website takes no responsibility for incorrect information, although best efforts are
                    made to be as accurate as possible: the information is automatically gathered and provided "as is", USE AT YOUR OWN RISK, and
                    remember that scams are out there and can skew the information. Always check that the vendor is genuine
                    via video interview and/or custom picture requests. If it is too good to be true, it probably is.</sup>
            </section>
        </footer>

        <div class="banner" v-if="!subscription || subscription.status !== 'active'">
            <router-link to="/app/supporter">Become a supporter</router-link>
            to help us out and see all adverts on record and statistics.
        </div>
    </div>
</template>

<script>
import Firebase from 'firebase/app'
import {mapState} from 'vuex';

export default {
    name: "Shell",
    data: () => {
        return {
            // search: '',
        }
    },
    mounted() {
    },
    computed: {...mapState(['user', 'rbac', 'subscription'])},
    methods: {
        logout() {
            Firebase.auth().signOut().then(function () {
                console.log('firebase signed out');
                window.location.href = '/';
            }, function (error) {
                console.log('firebase COULD NOT SIGN OUT', error);
            });
        }
    },
}
</script>

<style lang="stylus">

.paging {

    white-space: nowrap;

    small {
        display: inline-block;
        margin-right: 1rem;
    }

    ul {
        display: inline-block;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        display: inline-block;
        padding: 0 .1rem;


        a {
            border: 2px solid transparent;
            color: #222 !important;
            background: rgba(0, 0, 0, .06);
            padding: .4rem .8rem;
            border-radius: 4px;
            text-transform: uppercase;
            font-size: .85rem;
            box-shadow none;
        }

        a:hover {
            color: white !important;
            background: #1022DE;
            text-decoration: none;
            border: 2px solid rgba(0, 0, 0, .2);
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
        }

        a.router-link-active {
            display: inline-block;
            border: 1px solid rgba(0, 0, 0, .3);
            color: #222 !important;
            background: transparent;
            padding: .1rem .8rem;
            border-radius: 4px;
            box-shadow: inset 0 .1rem .3rem rgba(0, 0, 0, .3);
        }
    }
}
</style>
<template>
    <div class="autocomplete">
        <input type="text" placeholder="Machine Search" v-model="search" class="form-textinput"
               :class="{ 'loading-circle' : (search.length > 1), 'hide-loading-circle': results.length > 0 || results.length == 0 && !loading  }" @keyup="!loading ? onKeyEvent() : ''"/>
        <ul class="autocomplete-results" v-if="results.length">
            <li class="autocomplete-head">Search Results</li>
            <li class="autocomplete-result" v-for="item in results" :key="item.id" @click="search='';onSelect(item)">
                {{ item.name }} <sup v-if="item.short">{{ item.short }}</sup> <small>{{ item.manufacturer }}, {{ moment(item.manufactured).format('YYYY') }}</small>
            </li>
        </ul>
    </div>
</template>

<script>
import Vue from 'vue';

export default {
    name: "MachineSearchField",
    data: () => {
        return {
            results: [],
            loading: false,
            search: '',
        }
    },
    methods: {
        onSelect(item) {
            this.loading = false;
            this.results = [];
            console.log(item.id);
            this.$emit('selected', item);
        },

        onKeyEvent(keywordEntered) {
            this.results = [];
            this.loading = false;
            if (this.search.length > 1) {
                this.loading = true;
                const to_search = encodeURIComponent(this.search.split('/').join('').split('\\').join(''))
                this.axios.get(Vue.prototype.base + "/machines/search/" + to_search)
                    .then(response => {
                        //because the name can contains partial name, we only include the country that contains the keyword text
                        let newData = [];
                        response.data.data.forEach(function (item, index) {
                            newData.push(item);
                        });
                        this.results = newData;
                        this.loading = false;
                    })
                    .catch(e => {
                        console.warn(e);
                        this.loading = false;
                        this.results = [];
                    })
            } else {
                this.loading = false;
                this.results = [];
            }
        },
    }
}
</script>

<style scoped>
.loading-circle {
    background-color: #ffffff;
    background-image: url(../assets/loading.gif);
    background-size: 16px 16px;
    background-position: right center;
    background-repeat: no-repeat;
}

.hide-loading-circle {
    background: none;
}

.form-textinput {
}

.autocomplete {
    position: relative;
}

.autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    position: absolute;
    border-radius: 0 .3rem .3rem .3rem;
    height: 15rem;
    width: 35rem;
    overflow: auto;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .23), 0 0 3px rgba(0, 0, 0, .23);
    z-index: 400;
}

.autocomplete-result {
    list-style: none;
    padding: 0 1rem;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    line-height: 2;
}

.autocomplete-result small {
    float: right;
    display: inline-block;
    color: gray;
    line-height: 2;
    margin-top: .1rem;
}

.autocomplete-result:hover {
    background: #1022DE;
    color: white !important;
}

.autocomplete-head {
    background: #ffcd11;
    padding: .3rem 1rem;
}

@media only screen
and (max-device-width: 812px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
    .autocomplete-results {
        width: 100%;
    }
}
</style>
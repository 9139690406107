import Vue from 'vue';
import Vuex from 'vuex';

let store = (() => {
    Vue.use(Vuex);
    return new Vuex.Store({
        state: {
            user: null,
            rbac: null,
            subscription: null,
        },
        mutations: {
            setUser(state, user) {
                state.user = user;
            },
            setRBAC(state, rbac) {
                state.rbac = rbac;
            },
            setSubscription(state, subscription) {
                state.subscription = subscription;
            },
        }
    })
})();
export default store;
<template>
    <div>
        <aside>
            <section>
                <h2>Shadow Banned <small v-if="ads">({{banned_count}} / {{ads.length}})</small><small v-else>(loading...)</small></h2>
            </section>
        </aside>

        <div v-if="loading && !ads.length || !ads">
            <div class="loader">
                <img src="/img/loader.gif" alt="">
            </div>
            <br>
        </div>

        <main v-if="ads.length">
            <section>
                <table v-if="ads">
                    <tr>
                        <th class="mid">Title from source</th>
                        <th class="tright">Price</th>
                        <th>Machine (auto-matched)</th>
                        <th>Location</th>
                        <th>Source</th>
                        <th>First Seen</th>
                        <th>Last Seen</th>
                        <th>Duration</th>
                        <th>&nbsp;</th>
                    </tr>
                    <tbody v-for="advert in adsList" :key="advert.id">
                    <tr :class="{old: moment().unix()*1000 - advert.updated > 1000 * 60 * 45 }">
                        <td class="mid"><a target="_blank" rel="noopener noreferrer" :href="advert.url" class="crossed">{{advert.title}}</a></td>
                        <td class="tright">${{advert.price.toLocaleString()}}</td>
                        <td class="nowrap"><a target="_blank" :href="`https://opdb.org/search?q=${advert.opdb_id}`">{{advert.name}}</a><sup v-if="advert.short">{{(advert.short)}}</sup></td>
                        <td>{{advert.loc ? advert.loc.split(',')[1] : '&mdash;'}}</td>
                        <td class="nowrap">{{advert.source}}</td>
                        <td class="nowrap">{{moment(advert.added).fromNow()}}</td>
                        <td class="nowrap">{{moment(advert.updated).fromNow()}}</td>
                        <td class="nowrap">{{moment.duration(advert.updated - advert.added).humanize()}}</td>
                        <td>
                            <router-link :to="`/app/edit/${advert.id}`">admin</router-link>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="paging fright">
                    <small>Pages</small>
                    <ul>
                        <li v-for="page in total_pages" :key="page">
                            <router-link :to="`/app/list/${page}`">{{page}}</router-link>
                        </li>
                    </ul>
                </div>
                <small>&mdash;</small>
            </section>
        </main>

        <model :api="`/forsale/banned/${page}`"
               v-model="ads"
               @page="page_current = $event"
               @total_pages="total_pages = $event"
               @loading="loading = $event"></model>
    </div>
</template>

<script>

export default {
    name: "ShadowList",
    data: () => {
        return {
            ads: [],
            loading: true,
            page_current: 0,
            total_pages: 0,
        }
    },
    computed: {
        page() {
            return this.$route.params.page;
        },
        adsList() {
            return this.ads
                .filter(ad => ad.shadowbanned)
                .sort((a, b) => {
                    if (a.added === b.added)
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    return a.added > b.added ? -1 : 1;
                });
        },
        banned_count() {
            return this.ads.filter(ad => ad.shadowbanned).length;
        }
    },
    mounted() {
    },
    methods: {},
}
</script>

<style scoped>

</style>
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import axios from 'axios';
import firebase from 'firebase/app';
import {browser_notify} from './utils/BrowserNotify';
import Model from '@/model';
import store from './store';
import routes from '@/routes';

Vue.config.productionTip = false;

Vue.prototype.moment = window.moment; // from cdn, see index.html
Vue.prototype.browser_notify = browser_notify;
Vue.prototype.axios = axios;
Vue.component('model', Model);
Vue.use(VueRouter)

const router = new VueRouter(routes);

Vue.prototype.base = (location.hostname === "localhost") ? 'http://localhost:8484/api' : '/api';

firebase.initializeApp({
    apiKey: process.env.VUE_APP_FIREBASE_KEY,
    authDomain: "pinsales-69bcf.firebaseapp.com",
    databaseURL: "https://pinsales-69bcf.firebaseio.com",
    projectId: "pinsales-69bcf",
    storageBucket: "pinsales-69bcf.appspot.com",
    messagingSenderId: process.env.VUE_APP_MSG_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
});

let app;
firebase.auth().onAuthStateChanged((user) => {
    if (app)
        return;

    store.commit('setUser', user);

    if (user) {
        user.getIdTokenResult().then((user_info) => {

            store.commit('setRBAC', user_info.claims);

            user.getIdToken().then(token => {
                axios.defaults.headers.common['Authorization'] = "Bearer " + token;
                if (user_info.claims['subscription']) {
                    axios.get(Vue.prototype.base + "/user/check-subscription/" + user_info.claims.subscription, {})
                        .then(r => {
                            store.commit('setSubscription', r.data);
                        })
                        .catch(e => {
                            throw(e);
                        });
                }
            }).catch(e => {
                throw(e);
            });
        });
    }

    app = new Vue({
        router,
        store: store,
        render: h => h(App)
    }).$mount('#app');

    // app didn't exist so the page just loaded
    // already logged in so move on to /app
    if (user) {
        if (router.currentRoute.path === "/") {
            router.replace("/app")
        }
    }
})

router.beforeEach((to, from, next) => {
    const user_only_route = to.matched.some(x => x.meta.user_only || x.meta.admin_only);
    if (user_only_route && !firebase.auth().currentUser) {
        next('/');
    }

    const admin_only_route = to.matched.some(x => x.meta.admin_only);
    if (admin_only_route && (!store.state.rbac || !store.state.rbac.admin)) {
        next('/')
    }

    const customer_only_route = to.matched.some(x => x.meta.customer_only); // TODO revise this
    if (customer_only_route && (!store.state.subscription || store.state.subscription.status !== 'active')) {
        next('/app/supporter');
    }

    // if (customer_only_route && (!store.state.rbac || !store.state.rbac.customer || store.state.rbac.admin)) {
    //     next('/')
    // }

    next();
});

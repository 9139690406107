<template>
    <main>
        <section>
            <h1>Machine Malfunction</h1>
            <h2>Uh Oh, <b>Operator Error</b></h2>
            <hr>
            <h4>ERROR code: {{status}}</h4>
            <hr>
            <br>
            <p>Something went really wrong. It's probably our fault. <a href="/">Please try again later.</a></p>
        </section>
    </main>
</template>

<script>
export default {
    name: "Errored",
    computed: {
        status() {
            return this.$route.params.status;
        },
    }
}
</script>

<style scoped>
</style>
<template>
    <div>
        <aside v-if="show_title">
            <section>
                <div class="search" v-if="ads && !filter">
                    <a href="#" class="pastille" @click="active_only = expired_only = false">All</a>
                    <a href="#" class="pastille" :class="{active: active_only}" @click="active_only = !active_only; expired_only = false">Active</a>
                    <a href="#" class="pastille" :class="{active: expired_only}" @click="expired_only = !expired_only; active_only = false">Expired</a>
                    <img src="/img/search.svg" id="search_icon">
                    <input type="text" placeholder="Machine name or initials" v-model.lazy.trim="search">
                </div>
                <h2>For Sale <small v-if="ads && ads.length">({{ active_count }}/{{ ads.length }})</small><small v-else>(loading...)</small></h2>
            </section>
        </aside>

        <div v-if="loading && ads && !ads.length || !ads">
            <div class="loader">
                <img src="/img/loader.gif" alt="">
            </div>
            <br>
        </div>

        <main v-if="ads && ads.length">
            <section>
                <table class="list" v-if="ads">
                    <tr>
                        <th class="mid">Title from advert</th>
                        <th class="tright">Price</th>
                        <th>Machine (auto-matched*)</th>
                        <th>Manufacturer(*)</th>
                        <th>Location</th>
                        <th>Source</th>
                        <th>First Seen</th>
                        <th>Last Seen</th>
                        <th>Duration</th>
                        <th>&nbsp;</th>
                    </tr>

                    <tbody v-for="advert in adsList" :key="advert.id">
                    <tr :class="{old: moment().unix()*1000 - advert.updated > 1000 * 60 * 60 * 3.5}">

                        <td class="mid">
                            <sup class="project" v-if="advert.is_project" title="This is a project game, likely not working or missing parts">Project</sup>
                            <sup class="scam" v-if="advert.is_scam" title="We think this is fishy. This is likely to be a scam! Beware!">Likely Scam</sup>
                            <a target="_blank" rel="noopener noreferrer" :href="advert.url">{{ advert.title }}</a>
                        </td>

                        <td class="tright">${{ advert.price.toLocaleString() }}</td>

                        <td class="nowrap">
                            <router-link :to="`/app/machine/${advert.opdb_id}`" v-if="subscription && subscription.status === 'active'">{{ advert.name }}</router-link>
                            <span v-else>{{ advert.name }}</span>
                            <sup v-if="advert.short">{{ (advert.short) }}</sup></td>

                        <td class="nowrap">{{ advert.manufacturer }}, {{ moment(advert.manufactured).format('YYYY') }}</td>

                        <td class="nowrap">{{ advert.loc ? advert.loc.split(',')[1] : '&mdash;' }}</td>

                        <td class="nowrap">{{ advert.source }}</td>

                        <td class="nowrap">{{ moment(advert.added).fromNow() }}</td>

                        <td class="nowrap">{{ active_time_count(advert) }}</td>

                        <td class="nowrap">{{ moment.duration(advert.updated - advert.added).humanize() }}</td>

                        <td>
                            <router-link :to="`/app/edit/${advert.id}`" v-if="rbac && rbac.admin">Edit</router-link>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div v-if="subscription && subscription.status == 'active'">
                    <div class="paging fright" v-if="search.length < 2">
                        <small>Pages</small>
                        <ul>
                            <li v-for="page in total_pages" :key="page">
                                <router-link :to="`/app/list/${page}`">{{ page }}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-else class="paging fright"><router-link to="/app/supporter">Become a supporter to see all adverts and statistics</router-link></div>
                <small>(*): Auto-matched. May be inaccurate at first.</small>
            </section>
        </main>

        <model :api="`/forsale/list/${page}`"
               v-if="search.length <= 1"
               v-model="ads"
               @page="page_current = $event"
               @total_pages="total_pages = $event"
               @loading="loading = $event"></model>
        <model :api="`/forsale/search/${search_clean}/${page}`"
               v-if="search.length > 1"
               v-model="ads"
               @page="page_current = $event"
               @total_pages="total_pages = $event"
               @loading="loading = $event"></model>
    </div>

</template>

<script>

import {mapState} from 'vuex';

export default {

    name: "Main",
    data: () => {
        return {
            ads: [],
            search: '',
            active_only: false,
            expired_only: false,
            loading: true,
            page_current: 0,
            total_pages: 0,
        }
    },
    watch: {
        search() {
        }
    },
    props: {
        filter: {
            default: '',
            type: String,
            required: false,
        },
        show_title: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        ...mapState(['user', 'rbac', 'subscription']),
        page() {
            if (this.subscription && this.subscription.status === 'active')
                return this.$route.params.page || 1;
            else
                return 1;
        },
        search_clean() {
            return this.search.split('/').join('').split('\\').join('');
        },
        adsList() {
            return this.ads
                .filter(ad => !ad.shadowbanned)
                .filter(ad => this.active_only ? this.moment().unix() * 1000 - ad.updated < 60 * 1000 * 60 * 3.5 : true)
                .filter(ad => this.expired_only ? this.moment().unix() * 1000 - ad.updated > 60 * 1000 * 60 * 3.5 : true)
                .sort((a, b) => {
                    if (a.added === b.added)
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    return a.added > b.added ? -1 : 1;
                });
        },
        active_count() {
            return this.ads.filter(ad => this.moment().unix() * 1000 - ad.updated <= 60 * 1000 * 60 * 3.5 && !ad.shadowbanned).length;
        }
    },
    mounted() {
        if (this.filter) {
            // changing this causes the watcher to pull
            this.search = this.filter;
        }
    },
    methods: {
        active_time_count(ad) {
            if (this.moment().unix() * 1000 - ad.updated > 60 * 1000 * 4) {
                return this.moment(ad.updated).fromNow();
            }
            return "Now";
        }
    }
}
</script>

<style scoped lang="stylus">
table {
    margin-bottom: 4rem;
}

</style>
<template>
    <div>
        <aside>
            <section>
                <h2>Machine Details &gt; <span v-if="machine">{{ machine.name }}
                    <sup v-if="machine.short">{{ (machine.short) }}</sup></span></h2>
            </section>
        </aside>
        <main>
            <section>
                <table v-if="machine && ads" class="machine_details">
                    <tr>
                        <td class="image">
                            <img :src="machine.img">
                        </td>
                        <td>
                            <h2>{{ machine.name }} ({{ machine.manufacturer }})</h2>
                            <p>Manufactured<b> {{ machine.manufactured.replace('-01-01', '') }}</b><br>
                                <b>{{ machine.players || "(?)" }}</b> Player(s) Game<br>
                                Type: <b class="allcaps">{{ machine.type }}</b><br>
                                IPDB: <a target="_blank" :href="`https://www.ipdb.org/machine.cgi?id=${machine.ipdb}`" v-if="machine.ipdb">{{ machine.name }}</a>
                                <span v-else>&mdash;</span>
                            </p>
                        </td>
                        <td>
                            <h2>Price Range</h2>
                            <br>
                            <ul class="price_range">
                                <li>Max</li>
                                <li v-for="ad in ads_by_price" :key="ad.id">
                                    ${{ ad.price.toLocaleString() }} - {{ moment(ad.added).format('MMM YYYY') }}
                                </li>
                                <li>
                                    Min
                                </li>
                            </ul>
                        </td>
                        <td>
                            <h2>Stats</h2>
                            <p>
                                Max: <b>${{ maxp.toLocaleString() }}</b><br>
                                Average ({{ ads.length }}): <b>${{ average.toLocaleString() }}</b><br>
                                Min: <b>${{ minp.toLocaleString() }}</b><br>
                            </p>
                        </td>
                    </tr>
                </table>
            </section>
        </main>

        <List :filter="oid" :show_title="true"></List>

        <model :api="`/forsale/by_machine/${this.oid}`" v-model="ads"></model>
        <model :api="`/machines/view/${this.oid}`" v-model="machine"></model>

    </div>
</template>

<script>
import List from '@/components/List';

// TODO handle reposters - "collapse" machines with the same title and loc following added time order.
// TODO Ebay being mostly auctions might extend the average clearing times if the game is on Ebay alone(?)

export default {
    name: "MachineView",
    components: {List},
    data: () => {
        return {
            machine: null,
            ads: [],
        }
    },
    computed: {
        oid() {
            return this.$route.params.oid;
        },
        ads_by_price() {
            return this.ads.concat().sort((a, b) => a.price > b.price ? -1 : 1);
        },
        average() {
            if (!this.ads || !this.ads.length)
                return 0;
            return Math.floor(this.ads.map(x => x.price).reduce((a, b) => a + b) / this.ads.length);
        },
        maxp() {
            if (!this.ads || !this.ads.length)
                return 0;
            return this.ads.map(x => x.price).reduce((a, b) => a > b ? a : b);
        },
        minp() {
            if (!this.ads || !this.ads.length)
                return 0;
            return this.ads.map(x => x.price).reduce((a, b) => a < b ? a : b);
        }
    },
    mounted() {
    },
    methods: {}
}
</script>

<style scoped lang="stylus">
td {
  vertical-align top;

  h2 {
    margin: 0;
    padding: 0;
    margin-top: -.5rem;
  }
}

td {
  margin-right: 5rem;
}


.price_range {
  margin: 0;
  padding: 0;
  border-left: 2px solid #555;

  li {
    list-style: square;
    color: #555555;
    margin: 0;
    padding: 0;
    margin-left: .8rem;
    margin-top: -.4rem;
    line-height: 1.6;
  }
}
</style>
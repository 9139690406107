<template>
    <div>
        <div class="supporter">
            <div class="box">
                <h3>Your subscription is <b v-if="subscription">{{ subscription.status }}</b><b v-else>...</b></h3>
                <h1>$8.99<sub><small>/ month</small></sub></h1>
                <p>Next renewal will occur <b>{{ renewal_date }}</b>.</p>
                <p><small>Payment handled by Stripe. Cancel any time.</small></p>
                <button id="basic-plan-btn" class="buybtn" @click="cancelsub">Cancel Subscription</button>
            </div>
            <div class="box">
                <h3>Pinsales</h3>
                <p>Pinsales is a passion project and the money raised from pinsales goes into paying the bills and making the product better.</p>
                <br>
                <h3>Thank you for support!</h3>
                <p>Thanks for helping out!</p>

                <div id="error-message"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import axios from 'axios';
import Vue from 'vue';

export default {
    name: "SupporterActive",
    computed: {
        ...mapState(['user', 'rbac', 'subscription']),
        renewal_date() {
            if (this.subscription)
                return new Date(this.subscription.current_period_end * 1000).toLocaleDateString();
            else
                return '...';
        }
    },
    methods: {
        cancelsub() {
            let sure = confirm("Are you sure you'd like to cancel your subscription?");
            if (sure) {
                axios.post(Vue.prototype.base + "/user/cancel-subscription", {
                    data: {
                        sub_id: this.subscription.id
                    }
                }).then(res => {
                    if (res.status === 200) {
                        this.browser_notify("Success! You won't be charged again.");
                        this.$router.push('/app/supporter');
                    }

                }).catch(err => {
                    console.warn(err);
                });
            }
        }

    }
}
</script>

<style scoped lang="stylus">

.supporter {
    display flex;
    justify-content flex-start
}

.box h3 {
    color: white;
    text-shadow 1px 1px 1px rgba(0, 0, 0, .5)
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.box {
    font-size large
    font-weight lighter;
    line-height 1.5;
    text-shadow 1px 1px 0px rgba(0, 0, 0, .4)
    padding: 1rem;
    border-radius: 4px;
    background: #0e58e9;
    margin: 5rem auto;
    width: 40% !important;
    max-width: 35rem;
    color: white;
}


.box:nth-of-type(1) {
    h3 {
        color: black !important;
        text-shadow none;
        border-bottom 1px solid rgba(0, 0, 0, .3);
    }
    background: lightgrey;
    color: black !important;
    text-shadow none;
}

.buybtn {
    padding: 1rem 3rem;
    border: 2px solid transparent;
    color: white !important;
    background: rgba(0, 0, 0, .25);
    border-radius: 4px;
    text-transform: uppercase;
    font-size: .85rem;
    box-shadow none;
    margin: 1rem auto;
}

.buybtn:hover {
    color: white !important;
    background: #1022DE;
    text-decoration: none;
    border: 2px solid rgba(0, 0, 0, .2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

small {
    font-size: .9rem;
}

</style>
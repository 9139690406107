const createNotification = function (title, msg, redirect) {
    let note = new Notification(title, {
        body: msg,
        tag: 'tag-' + parseInt(Math.random() * 1256),
        icon: '/img/favicon-96x96.png',
        requireInteraction: false,
    });
    if (redirect) {
        note.onclick = () => {
            if (this['$router'])
                this.$router.push(redirect);
        };
    }
    return note;
};

const browser_notify = (title, msg, redirect) => {

    if (Notification.permission === 'granted') {
        createNotification(title, msg);
    } else if (Notification.permission !== 'denied') {
        Notification.requestPermission(function (permission) {
            if (permission === 'granted') {
                createNotification(title, msg);
            } else {
                console.log('not allowed to notify');
            }
        });
    }
}

export {browser_notify};